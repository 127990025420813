$(document).ready( () => {
  $('.responsive').slick({
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 4,
    // nextArrow: '<i class="fa fa-arrow-right"></i>',
    // prevArrow: '<i class="fa fa-arrow-left"></i>',
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  });

});