$('[data-fancybox="images"]').fancybox({
    buttons : [
      'zoom',
      'close'
    ],
    gutter: 50,
    infobar: false,
    helpers: {
      titleShow:true,
      titlePosition:'over'
    }
  });