$(document).ready(() => {
  $('.product-slide').slick({
    centerMode: false,
    centerPadding: '5px',
    slidesToShow: 4,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
    
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  });


  $('#calculator-result').on('click', () => {
    let measurment = $('#measurment-type').val();
    let productPrice = $('#productPrice').val();

    const priceString = productPrice.split("лв");
    const productPriceNumber = Number(priceString[0])

    let count = $('#how-much').val();

    let result = '';
    let totalPrice = '';


    if (measurment === 'бр' || measurment === 'бр.') {
      result = count;
      totalPrice = (result * productPriceNumber).toFixed(3);
      $('.result').text(`При поръчка на ${count}бр, Вие получавате цена ${totalPrice} лв`)
    } else {
      if (measurment === 'м3') {
        let thickness = $('#thickness').val() / 100;
        let width = $('#width').val() / 100;
        let length = $('#length').val() / 100;
        // console.log(thickness, width, length);

        result = (Number(thickness) * Number(width) * Number(length)) * Number(count);

        totalPrice = (result * productPriceNumber).toFixed(2);
        $('.measurment-result').text(result.toFixed(3));
        // $('.result').text(`При поръчка на ${count}бр от избраните размери, Вие получавате ${(result).toFixed(3)} ${measurment} на цена ${totalPrice} лв`)

      } else if (measurment === 'м2') {
        let width = $('#width').val() / 10;
        let length = $('#length').val() / 100;
        console.log(width, length);

        result = (Number(width) * Number(length)) * Number(count) / 10;

        totalPrice = (result * productPriceNumber).toFixed(2);
        $('.measurment-result').text(result.toFixed(3));

        // $('.result').text(`При поръчка на ${count}бр от избраните размери, Вие получавате ${(result).toFixed(3)} ${measurment} на цена ${totalPrice} лв`)

      } else if (measurment === 'м' || measurment === 'м.') {
        let length = $('#length').val();
        result = Number(length) * count / 100;

        totalPrice = (result * productPriceNumber).toFixed(2);
        $('.measurment-result').text(result.toFixed(3));

        // $('.result').text(`При поръчка на ${count}бр от избраните размери, Вие получавате ${result} ${measurment} на цена ${totalPrice} лв`)
      }
      $('.selected-count').text(count);
      $('.total-price').text(totalPrice);
    }
    $('.result').show();
  })

  $('#measurment-options').on('change', (e) => {
    // console.log(e.target.value)
    let measurment = $('#measurment-options').val();
    let productPrice = $('#productPrice').val();

    const priceString = productPrice.split("лв");
    const productPriceNumber = Number(priceString[0])

    let count = $('#how-much').val();

    let result = '';
    let totalPrice = '';


    if (measurment === 'm3') {
      let thickness = $('#thickness').val() / 100;
      let width = $('#width').val() / 100;
      let length = $('#length').val() / 100;
      // console.log(thickness, width, length);

      result = (Number(thickness) * Number(width) * Number(length)) * Number(count);

      // totalPrice = (result * productPriceNumber).toFixed(2);
      $('.measurment-result').text(result.toFixed(3));
      // $('.result').text(`При поръчка на ${count}бр от избраните размери, Вие получавате ${(result).toFixed(3)} ${measurment} на цена ${totalPrice} лв`)

    } else if (measurment === 'm2') {
      let width = $('#width').val() / 10;
      let length = $('#length').val() / 100;
      // console.log(width, length);

      result = (Number(width) * Number(length)) * Number(count) / 10;

      // totalPrice = (result * productPriceNumber).toFixed(2);
      $('.measurment-result').text(result.toFixed(3));

      // $('.result').text(`При поръчка на ${count}бр от избраните размери, Вие получавате ${(result).toFixed(3)} ${measurment} на цена ${totalPrice} лв`)

    } else if (measurment === 'm') {
      let length = $('#length').val();
      result = Number(length) * count / 100;

      // totalPrice = (result * productPriceNumber).toFixed(2);
      $('.measurment-result').text(result.toFixed(3));

      // $('.result').text(`При поръчка на ${count}бр от избраните размери, Вие получавате ${result} ${measurment} на цена ${totalPrice} лв`)
    }
    $('.selected-count').text(count);
    // $('.total-price').text(totalPrice);

  })

});