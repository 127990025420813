jQuery(() => {
// $(document).ready(() => {

    $('#top').on('click', (event) => {
        event.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
        return false;
    });


    $(window).scroll(function () {
        if ($(this).scrollTop() > 1000) {
            $('#top').fadeIn();
        } else {
            $('#top').fadeOut();
        }
    });


    $('.burger-container').on('click', () => {
        $('.responsive-menu').toggleClass('menu-opened');
    })

    mobileNav = $(function () {
        let wrapper = $(".body-container-wrapper"),
            mobileNavButton,
            mobileNavPanel = $(".mobile-categories-menu");

        $(document).on("click", ".mobile-categories-menu-button, .body-container-wrapper.pushed", toggleNav);

        function toggleNav(e) {
            mobileNavButton = $(".mobile-categories-menu-button");
            if (mobileNavPanel.hasClass("is-open")) {
                closeNav();
            } else {
                openNav();
            }
            e.preventDefault();
            e.stopPropagation();
        }

        function openNav() {
            wrapper.addClass("pushed");
            mobileNavPanel.addClass("is-open");
            console.log("open");
        }

        function closeNav() {
            wrapper.removeClass("pushed");
            mobileNavPanel.removeClass("is-open");
            console.log("closed");
        }
    });

    $('.open-lis').on('click', function (e) {
        $(this).find('i').toggleClass('rotate');
        $(this).siblings("ul").slideToggle("slow");
    });

    let url = window.location.href;
    let id = url.split("/").pop();
    $('.categories > li').find('ul>li').each(function () {
        if ($(this).attr('id') == id) {
            $(this).parent().show("slow");
        }
    });

    $('.categories.desktop li').on('click', (e) => {
        $('.categories.desktop li').removeClass('bckgr');
        $('i').removeClass('rotate');

        let element = e.target;

        $(element).find('i').addClass('rotate');
        $(element).addClass('bckgr');

        let id = $(element).attr('id');
        let elName = '#hover-subcategory-menu-' + id;

        $('.hover-subcategory-menu').hide()

        $(elName).show()

    })

    $('.navigation-main').on('mouseenter', () => {
        $('.hover-subcategory-menu').hide()
    })


    $('.info-ribbon').on('mouseenter', () => {
        $('.hover-subcategory-menu').hide()
    })

    // $('.hover-subcategory-menu').on('mouseleave', () => {
    //     $('.hover-subcategory-menu').hide()
    // })

    $('.subcategory a').on('mouseover', (e) => {
        let id = e.target.id;
        let imageUrl = $('#subcategory-image-holder-' + id).val();
        let image = imageUrl == '' ? '' : JSON.parse($('#subcategory-image-holder-' + id).val())
        if (image && image !== null && image !== '') {
            $('#subcategory-image-' + id).show();
            $('.category-image').hide();
        }
    })

    $('.subcategory a').on('mouseleave', (e) => {
        let id = e.target.id;
        $('#subcategory-image-' + id).hide();
        $('.category-image').show();
    })
   
});